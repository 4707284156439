import * as React from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import SimplifiedLandingPage from "../components/PageSegments/SimplifiedLandingPage";
import { LOCAL_STORAGE_VARIANT_KEY } from "./special-welcome";
import { useABTest } from "../hooks/useABTest";

const TEST_NAME = "202503_SkillBasedOnboarding";
const TEST_DATA = {
  testName: TEST_NAME,
  variants: {
    control: "control",
    variant: "variant",
  },
  weights: {
    variant: 0.5,
    control: 0.5,
  },
};

// used for Google traffic
export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const variant = useABTest(TEST_DATA);

  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  const gclidParam = urlParams.get("gclid") ?? undefined;

  React.useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  React.useEffect(() => {
    if (gclidParam) {
      setMixpanelProperties({
        gclid: gclidParam,
      });
    }
  }, [gclidParam]);

  React.useEffect(() => {
    const variantKey = variant === "variant" ? "variant" : "simplified";
    localStorage.setItem(LOCAL_STORAGE_VARIANT_KEY, variantKey);
  }, [variant]);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout>
  );
};
